<template>
  <div></div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
};
</script>

<style>
</style>
